import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import useDeepCompareEffect from 'use-deep-compare-effect';
import axios from "axios";
import moment from 'moment-timezone';
import * as _ from "lodash"; // https://lodash.com/docs

// toast, made available throughout app
import toast, { Toaster } from "react-hot-toast";

import ResourceOptionSet from "../components/forms/ResourceOptionSet";
import ResourceOptionsFilters from "../constants/ResourceOptionsFilters";

import AgenciesData from "../constants/AgenciesData";
import FormStatuses from "../constants/FormStatuses"; 
import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

import { TableWrapper } from "../components/tables/TableWrapper"; // used for consistency and layouts

// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// CONTENT
import StaticContent from "./elements/StaticContent";

// TABLES
import { TableMobilizationsNational } from "../components/tables/TableMobilizationsNational";
import {
    TableFireStatisticsAgenciesYesterday,
    TableFireStatisticsAgenciesFull,
    TableFireStatisticsAgenciesModified,
    TableFireStatisticsAgenciesMonitored,
    TableFireStatisticsAgenciesGrand,
    TableFireStatisticsAgenciesPrescribed,
    TableFireStatisticsAgenciesPriority,
    TableFireStatisticsAgenciesPredictions,
    TableFireStatisticsAgenciesPreparednessLevels,

    TableFireStatisticsAgenciesResourceRequirements,
    TableFireStatisticsAgenciesHelicopters,
    TableFireStatisticsMobilizationsCompact,

    // SECTION I agencies' demob compact REMOVED, as requested by client
    // TableFireStatisticsDemobilizationsCompact,
} from "../components/tables/TableFireStatistics";


var source;

function NationalSitrepInput( props ){

    // loading TRANSLATION functions
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('National SitRep Input') );

    // is API access available?
    const [isApi, setIsApi] = useState(null);

    // AUTH required to access page content
    const { isLoggedIn, activeUser } = props;

    // REDIRECT browser out of authenticated area if user not LOGGED IN with a CIFFC ROLE

    const history = useHistory();

    useEffect(() => {
        if (
            !isLoggedIn
            || !activeUser
            || activeUser.role === undefined 
            || activeUser.role.type !== 'ciffc'
        ) {
            history.push("/login");
        }
    }, [ isLoggedIn, activeUser ]);

    // TRACK form status (allowances, permissions, errors)

    const initialFormStatus = {
        isLocked: false, // as of this writing API will NOT return this info for national sitrep input, so this for should NEVER lock
        isSyncing: false,
        isError: false,
        requiresManualSync: false,
        displayStatus: null,
        displayLastUpdated: null,
        validationErrors: {}
    }

    const [formStatus, setFormStatus] = useState(initialFormStatus);

    // INITIALIZE data to send/sync with API

    const initialFormData = {
        // A: National Summary
            en_summary_comments : '', 
            fr_summary_comments : '',
            preparedness_level: '0',
            duty_officer: 0,
        // F: Outstanding Resource Requests
            outstanding_resource_requests: [],
        // H: Mobilizations
            mobs: [],
            mobs_grouped: [],
            national_to_mobs: {},
            national_from_mobs: {},
        // I: Demobilizations
            demobs: [],
            demobs_grouped: [],
        // K: US Fire Activity
            us_acres: '0',
            us_fires: '0',
            us_preparedness_level: '0',
            us_ytd_acres: '0',
            us_ytd_fires: '0'
    };

    const initialDataOutstandingResourceRequests = {
        for_agency: '',
        resources_en: '',
        resources_fr: '',
    };

    const initialDataMobs = {
        to: '',
        resources: '',
        from: ''
    };

    const initialDataDemobs = {
        to: '',
        resources: '',
        from: ''
    };

    let initalDataNationalMobs = {
        resources: ''
    };
        
        Object.keys(AgenciesData).map(function(dataKey) {
            initalDataNationalMobs[ dataKey ] = 0;
            return null;
        });
    
    const [formData, setFormData] = useState(initialFormData);

    const [resourceData, setResourceData] = useState();
    const [resourceOptions, setResourceOptions] = useState();
    const [resourceOptionsShort, setResourceOptionsShort] = useState();
    const [resourceOptionsExpanded, setResourceOptionsExpanded] = useState();
    
    const [dutyOfficersData, setDutyOfficersData] = useState([]);

    // used for ADD-ANOTHER series only

    const initialFormNational = {
        new_resource_key: '', 
    }
            
    const [formNational, setFormNational] = useState(initialFormNational);

    useEffect(() => {
        console.log('formNational CHANGED', formNational);
    }, [ formNational ]);

    // initial GET data

    useEffect(() => {

        // if PROP isLoggedIn passed in, we can assume this is for an AGENCY PAGE, and access requires authetication to the API
        if ( isLoggedIn ) {
			
			
            // fetch full INVENTORY definitions, to use in tables...
            
            const urlResourcesFull = ApiEndpoints.dipResourceOptions();

            axios
                .get(
                    urlResourcesFull                )
                .then(({ data }) => {
                    console.log( 'GET, full resources', data);
                    setResourceOptions(data);
                })
                .catch((error) => {
                    console.log( 'GET, error (full resources)', error);
                });
            
            // fetch INVENTORY definitions, to use in pulldowns...
            
            const urlResources = ApiEndpoints.dipResourceOptionsActive();

            axios
                .get(
                    urlResources                )
                .then(({ data }) => {
                    console.log( 'GET, resources', data);
                    setResourceData(data);
                })
                .catch((error) => {
                    console.log( 'GET, error (resources)', error);
                });
            axios
                .post(
	                urlResources,
	                JSON.stringify({shortnames: ResourceOptionsFilters.short}),
			        {
						headers: {
							// Overwrite Axios's automatically set Content-Type
							'Content-Type': 'application/json'
						}
			        }
                )
                .then(({ data }) => {
                    console.log( 'GET, resources, short', data);
                    setResourceOptionsShort(data);
                })
                .catch((error) => {
                    console.log( 'GET, error (resources, short)', error);
                });
            axios
                .post(
	                urlResources,
	                JSON.stringify({shortnames: ResourceOptionsFilters.expanded}),
			        {
						headers: {
							// Overwrite Axios's automatically set Content-Type
							'Content-Type': 'application/json'
						}
			        }
                )
                .then(({ data }) => {
                    console.log( 'GET, resources, expanded', data);
                    setResourceOptionsExpanded(data);
                })
                .catch((error) => {
                    console.log( 'GET, error (resources, expanded)', error);
                });


            // fetch DUTY OFFICER data, to use in pulldowns...

            const urlDutyOfficers = ApiEndpoints.dipDutyOfficersOptions();

            axios
                .get(
                    urlDutyOfficers,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {
                    console.log( 'GET, duty officers', data);
                    setDutyOfficersData(data);
                })
                .catch((error) => {
                    console.log( 'GET, error (duty officers)', error);
                });

            // update FORM STATUS to display syncing is STARTING...

            setFormStatus({
                ...formStatus, 
                isSyncing: true
            });
            
            const urlSitrepGet = ApiEndpoints.dipSitrepNationalGet();

            axios
                .get(
                    urlSitrepGet,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {

                    // GROUP data sets by KEY, for UI requirements...
                    data.mobs_grouped = _.values(
                        _.groupBy( data.mobs, function(i) {
                            return i.to + ' ' + i.from;
                        })
                    );
                    data.demobs_grouped = _.values(
                        _.groupBy( data.demobs, function(i) {
                            return i.to + ' ' + i.from;
                        })
                    );

                    // set FORM DATA from fetch...
                    setFormData(
                        { ...formData, ...data }
                    );
                    
                    console.log( 'GET, data', data );
                    
                    // figure out default status, and then determine DISPLAYABLE status from constant file
                    let displayStatus = 'N';
                    if ( data.status ) displayStatus = data.status.toUpperCase(); // currently, NATIONAL sitrep doesn't have a "state", but we'll keep an eye for future addition?
                    if ( data.last_edit && !data.status ) displayStatus = 'UNPUBLISHED'; // unpublished "state", if we don't recognize a passed-in "state" from API
                    if ( data.is_published ) displayStatus = 'PUBLISHED'; // published "state"
                    displayStatus = FormStatuses[displayStatus].title;

                    let newFormStatus = {
                        isSyncing: false,
                        isError: false,
                        displayLastUpdated: data.last_edit,
                        displayStatus: displayStatus
                    };

                    if ( data.hasOwnProperty('requires_manual_sync') ) {
                        if (
                            data.requires_manual_sync === true
                            || data.requires_manual_sync.toString().toUpperCase() === 'T'
                            || data.requires_manual_sync.toString().toUpperCase() === 'TRUE'
                            || data.requires_manual_sync.toString().toUpperCase() === '1'
                        ) {
                            newFormStatus.requiresManualSync = true;
                        } else {
                            newFormStatus.requiresManualSync = false;
                        }
                    }

                    // update FORM STATUS to display syncing is DONE...
                    setFormStatus({
                        ...formStatus,
                        ...newFormStatus
                    });

                    // flag API as accessible
                    setIsApi(true);
                })
                .catch((error) => {
                    console.log( 'GET, error', error);

                    let newFormStatus = {
                        isError: true,
                        isSyncing: false
                    }

                    // update FORM STATUS to display syncing is DONE...
                    setFormStatus({
                        ...formStatus, 
                        ...newFormStatus
                    });

                    // flag API as inaccessible, show WARNING
                    setIsApi(false);
                });

        } else {
            setFormData( null );
        }

    }, [ isLoggedIn ]);

    // prep FORM data, handlers, etc

    const formDataBefore = useRef(formData);

    const handleChange = (event) => {
        let updatedFormData  = _.cloneDeep(formData); // DEEP CLONING the object, to ensure the SETSTATE about to happen actually "sees" the state change properly
        let target_name = _.clone(event.target.name);
        // the _.set function will split the path on both square brackets AND
        // on dots, but we sometimes have keys that have dots in them.
        if( target_name.match(/\[[^\]]*\.[^\]]*\]/) ){
	        if( !target_name.match(/'/) ){
				target_name = target_name.replace(/\[/,'[\'').replace(/\]/,'\']');
	        }else if( !target_name.match(/"/) ){
				target_name = target_name.replace(/\[/,'["').replace(/\]/,'"]');
	        }else{
		        // This ideally will NEVER happen since we don't have a good solution
		        console.log('Both single and double quotes in input name',target_name);
	        }
        }
        _.set( updatedFormData, target_name, event.target.value ); // using LODASH to parse a path of object keys, to set the appropriate value (useful in sub ARRAYs)
        setFormData({...formData, ...updatedFormData});
    };

    const handleBlur = (event, forceSync=false, forceSubmit=false ) => {
		
        console.log('TO sync', formData, formStatus);

        if ( isLoggedIn ) {

            // vanilla JS check if INPUT is valid based on input TYPE and PATTERN attributes first...
            if ( event && !event.target.checkValidity() ) {
                console.log('SYNC prevented, checkValidity() FAIL');

                let formErrors = formStatus.validationErrors;

                // try to determine best message to display
                let formErrorMessage = t('Invalid format');
                if ( event.target.type === 'number' ) { formErrorMessage = t('Invalid value.'); }
                if ( !event.target.value ) { formErrorMessage = t('This field is required.'); }

                // check for NON-REACT (hence lowercase) attribute indicating error to be displayed for ALTERNATE field
                if ( event.target.getAttribute('forinputname') ) {
                    _.set(formErrors, event.target.getAttribute('forinputname'), formErrorMessage);
                }
                // else set error for field MATCHING input name
                else {
                    _.set(formErrors, event.target.name, formErrorMessage);
                }

                // update FORM STATUS to display syncing is DONE...
                setFormStatus(
                    {
                        ...formStatus, 
                        isError: true,
                        validationErrors: formErrors,
                        checkValidity: 'here'
                    }
                );

                // TOAST
                toast.dismiss();
                toast.error(
                    t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                );

            }

            // only SYNC to API when someone is logged in AND when the form data has CHANGED since before the BLUR event
            else if (
                formDataBefore.current!==formData
                || forceSync // passed in PARAM that forces sync, even we we don't recognize the data as changed since last time
            ) {

                if ( forceSync ) { console.log('handleBlur, formChange pass BECAUSE FORCED'); }
                
                // ...assuming this clears at least the standard validity check (ABOVE), clear any error, trust API to let if still an issue
                if ( event ) {

                    // check for NON-REACT (hence lowercase) attribute indicating error to be cleared for ALTERNATE field
                    if ( event.target.getAttribute('forinputname') ) {
                        _.set(formStatus.validationErrors, event.target.getAttribute('forinputname'), null);
                    }
                    // else clear error for field MATCHING input name
                    else {
                        _.unset(formStatus.validationErrors, event.target.name);
                    }

                }

                // update FORM STATUS to display syncing is STARTING...
                setFormStatus({
                    ...formStatus, 
                    isSyncing: true,
                    displayStatus: false,
                });

                const urlPost = ApiEndpoints.dipSitrepNationalPost();
                
                let newFormStatus = {}

				if( source ){
					console.log("Cancel previous request");
					source.cancel();
				}
				
				source = axios.CancelToken.source();
				
                axios
                    .post(
                        urlPost,
                        formData,
                        {
                            headers: {
                                Authorization: "Bearer " + activeUser.jwt
                            },
                            cancelToken: source.token
                        }
                    )
                    .then(({ data }) => {
                        console.log("SYNCED, with response", data);

                        // figure out default status, and then determine DISPLAYABLE status from constant file
                        let displayStatus = 'N';
                        if ( data.status ) displayStatus = data.status.toUpperCase(); // currently, NATIONAL sitrep doesn't have a "state", but we'll keep an eye for future addition?
                        if ( data.last_edit && !data.status ) displayStatus = 'UNPUBLISHED'; // unpublished "state", if we don't recognize a passed-in "state" from API
                        if ( data.is_published ) displayStatus = 'PUBLISHED'; // published "state"
                        displayStatus = FormStatuses[displayStatus].title;

                        newFormStatus = {
                            isSyncing: false,
                            isError: false,
                            displayLastUpdated: data.last_edit,
                            displayStatus: displayStatus,
                            validationErrors: data.validationErrors || {}
                        }

                        if ( data.hasOwnProperty('requires_manual_sync') ) {
                            if (
                                data.requires_manual_sync === true
                                || data.requires_manual_sync.toString().toUpperCase() === 'T'
                                || data.requires_manual_sync.toString().toUpperCase() === 'TRUE'
                                || data.requires_manual_sync.toString().toUpperCase() === '1'
                            ) {
                                newFormStatus.requiresManualSync = true;
                                newFormStatus.displayStatus = FormStatuses['READY'].title; // update STATUS to indicate this FORM is ready to be manually synced (submitted) to API
                            } else {
                                newFormStatus.requiresManualSync = false;
                            }
                        }

                        // update FORM STATUS with new form STATUS on success
                        setFormStatus({
                            ...formStatus,
                            ...newFormStatus
                        });

                        // try to TRIGGER a submit, if that's valid to do...
                        setTriggerSubmit(forceSubmit);

                        // TOAST
                        toast.dismiss();

                        if ( !newFormStatus.requiresManualSync ) {
                            toast.success(
                                t('Data synced with server.')
                            );
                        } else {
                            toast.success(
                                t('Ready to update')
                            );
                        }

                    })
                    .catch((error) => {
                        
                        if (axios.isCancel(error)){
                            console.log("Canceled ", error);
                        }else if (
                            error.response !== undefined
                            && error.response.data !== undefined
                            && error.response.data.validationErrors !== undefined
                        ) {
                            console.log("SYNCED, with error", error);

                            newFormStatus = {
                                ...formStatus,
                                isSyncing: false,
                                isError: true,
                                validationErrors: error.response.data.validationErrors
                            }

                            if ( error.response.data.hasOwnProperty('requires_manual_sync') ) {
                                if (
                                    error.response.data.requires_manual_sync === true
                                    || error.response.data.requires_manual_sync.toString().toUpperCase() === 'T'
                                    || error.response.data.requires_manual_sync.toString().toUpperCase() === 'TRUE'
                                    || error.response.data.requires_manual_sync.toString().toUpperCase() === '1'
                                ) {
                                    newFormStatus.requiresManualSync = true;
                                } else {
                                    newFormStatus.requiresManualSync = false;
                                }
                            }

                            if ( error.response.data.hasOwnProperty('last_edit') ) { newFormStatus.displayLastUpdated = error.response.data.last_edit }
                            
                            if ( error.response.data.hasOwnProperty('status') ) { 
                                // figure out default status, and then determine DISPLAYABLE status from constant file
                                newFormStatus.displayStatus = 'N';
                                if ( error.response.data.status ) newFormStatus.displayStatus = error.response.data.status.toUpperCase(); // currently, NATIONAL sitrep doesn't have a "state", but we'll keep an eye for future addition?
                                if ( error.response.data.last_edit && !error.response.data.status ) newFormStatus.displayStatus = 'UNPUBLISHED'; // unpublished "state", if we don't recognize a passed-in "state" from API
                                if ( error.response.data.is_published ) newFormStatus.displayStatus = 'PUBLISHED'; // published "state"
                                newFormStatus.displayStatus = FormStatuses[newFormStatus.displayStatus].title;
                            }
                            
                            // update FORM STATUS to display and ERRORS from API (blindly replacing all that were there before?)
                            setFormStatus(newFormStatus);

                            // TOAST
                            toast.dismiss();
                            toast.error(
                                t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                            );
                        }

                        else {
                            console.log("SYNCED, with undisplayed/unknown error", error);

                            let newFormStatus = {
                                ...formStatus,
                                isSyncing: false,
                                isError: true,
                                displayStatus: 'error'
                            }

                            // update with UNKNOWN error?
                            setFormStatus(newFormStatus);

                            // TOAST

                            toast.dismiss();
                            toast.error(
                                t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                            );

                        }
                    })
                    .finally(() => {
                        // update BEFORE form data to match CURRENT form data
                        formDataBefore.current = formData;
                    });
                
            } else {
                console.log('SYNC prevented, passed checkValidity() but formData not changed');
            }

        }

    };

    // create generic SERIES add and remove methods, that would be ATTACHED (as onclicks) to related data in form data

    const seriesAddRow = ( formDataKey, initialData, newObjectKey=false ) => {

        console.log( 'seriesAddRow', formDataKey, initialData, newObjectKey );

        // we use LODASH to get and set to objects because it gives us flexibility on HOW DEEP IN THE OBJECT we want to change
        // JUL 2024, we switched object copying to LODASH deep cloning, to better ensure we don't have any shallow copying of objects happening, and ensure the various use effects recognize our changes

        // copy of related ARRAY to append to
        let addToSeries =  _.cloneDeep( _.get(formData, formDataKey) ); // const addToSeries = formData[formDataKey];

        // create UNIQUE (enough) key
        const newSeriesKey = formDataKey.replace('.','-') + '-' + Date.now();

        // appending to OBJECT passed in
        initialData.seriesKey = newSeriesKey;
        
        // appending to OBJECT with provided key
        if ( newObjectKey ) {
            // if key does NOT already exist
            if ( addToSeries.hasOwnProperty(newObjectKey) ) {
                // window.alert( t('That data is already ready to be updated.') );
            } else {
                addToSeries[newObjectKey] = initialData;
            }
        }

        // appending to ARRAY series
        else {
            addToSeries.push(initialData);
        }

        // updated STATE of form data
        let newFormData =  _.cloneDeep(formData); // {...formData};
        _.set(newFormData, formDataKey, addToSeries);

        // special case: if NATIONAL_TO_MOBS, we want to REPEAT the process to keep its sibling NATIONAL_FROM_MOBS in sync...!

        if ( formDataKey==='national_to_mobs' ) {

            let syncedFormDataKey = 'national_from_mobs';

            // copy of related ARRAY to append to
            let syncedAddToSeries =  _.cloneDeep( _.get(formData, syncedFormDataKey) ); // const addToSeries = formData[syncedFormDataKey];

            // create UNIQUE (enough) key
            const syncedNewSeriesKey = syncedFormDataKey.replace('.','-') + '-' + Date.now();

            // appending to OBJECT passed in
            let syncedInitialData = _.cloneDeep(initialData);
            syncedInitialData.seriesKey = syncedNewSeriesKey;
            
            // appending to OBJECT with provided key
            if ( newObjectKey ) {
                // if key does NOT already exist
                if ( syncedAddToSeries.hasOwnProperty(newObjectKey) ) {
                    // window.alert( t('That data is already ready to be updated.') );
                } else {
                    syncedAddToSeries[newObjectKey] = syncedInitialData;
                }
            }

            // appending to ARRAY series
            else {
                syncedAddToSeries.push(syncedInitialData);
            }

            // updated STATE of form data
            _.set(newFormData, syncedFormDataKey, syncedAddToSeries);

        }
        
        setFormData(newFormData);

    };

    // seriesAddRow("national_to_mobs", { ...initalDataNationalMobs, resources: formNational.new_resource_key }, formNational.new_resource_key);

    const seriesRemoveRow = (formDataKey, seriesKeyToRemove ) => {

        const isConfirmed = window.confirm( t('Are you sure you want to delete this item?') );

        /*
        // support STRING (for one remove key) or ARRAY (to remove same key form multiple OBJECTs)
        if ( !Array.isArray(formDataKey) ) {
            formDataKey = [formDataKey];
        }

        console.log('formDataKey', formDataKey);

        if ( isConfirmed ) {

            // we use LODASH to get and set to objects because it gives us flexibility on HOW DEEP IN THE OBJECT we want to change
            // JUL 2024, we switched object copying to LODASH deep cloning, to better ensure we don't have any shallow copying of objects happening, and ensure the various use effects recognize our changes

            let updatedFormData = _.cloneDeep(formData);

            // loop through the ARRAY of items to remove, and clear them out properly...

            formDataKey.forEach( (dataKey) => {

                // remove ITEM from ARRAY...
                if ( Array.isArray(updatedFormData[dataKey]) ) {
                    updatedFormData = {
                        ...updatedFormData,
                        [dataKey]: updatedFormData[dataKey].filter(x=>x.seriesKey!==seriesKeyToRemove)
                    };
                }

                // or delete KEY from object
                else {
                    delete updatedFormData[dataKey][seriesKeyToRemove];
                }
           
            });

            setFormData(updatedFormData);

            // handleBlur(null);

        }
        */
            
        console.log( 'seriesRemoveRow()', formDataKey, seriesKeyToRemove );

        if ( isConfirmed ) {

            // we use LODASH to get and set to objects because it gives us flexibility on HOW DEEP IN THE OBJECT we want to change
            // JUL 2024, we switched object copying to LODASH deep cloning, to better ensure we don't have any shallow copying of objects happening, and ensure the various use effects recognize our changes


            let newFormData = _.cloneDeep(formData); // { ...formData }; 

            let removeFromSeries = _.cloneDeep( _.get(formData, formDataKey) );

            if ( Array.isArray(removeFromSeries) ) {
                removeFromSeries = removeFromSeries.filter(x=>x.seriesKey!==seriesKeyToRemove);
            } else {
                delete removeFromSeries[seriesKeyToRemove];
            }

            _.set( newFormData, formDataKey, removeFromSeries );

            // special case: if NATIONAL_TO_MOBS, we want to REPEAT the process to keep its sibling NATIONAL_FROM_MOBS in sync...!
            if ( formDataKey==='national_to_mobs' ) {

                let syncedFormDataKey = 'national_from_mobs';

                let syncedRemoveFromSeries = _.cloneDeep( _.get(formData, syncedFormDataKey) );

                if ( Array.isArray(syncedRemoveFromSeries) ) {
                    syncedRemoveFromSeries = syncedRemoveFromSeries.filter(x=>x.seriesKey!==seriesKeyToRemove);
                } else {
                    delete syncedRemoveFromSeries[seriesKeyToRemove];
                }
    
                _.set( newFormData, syncedFormDataKey, syncedRemoveFromSeries );

            }

            setFormData(newFormData);
        }

    }

    // using CUSTOM HOOK to watch GROUPED SERIES, as default use-effect would "see" changes deeper than "surface level"...?

    useDeepCompareEffect(() => {
        
        // copy any GROUPED data sets back into normal object key, for SAVING to database...

        let newFormData = { ...formData };
        newFormData.mobs = _.flatMap(formData.mobs_grouped, x=>x);
        newFormData.demobs = _.flatMap(formData.demobs_grouped, x=>x);
        setFormData( newFormData );

        // ...and then let NORMAL react use-effect "see" that change to trigger DB save as needed

    }, [ 
        formData.mobs_grouped,
        formData.demobs_grouped
    ]);

    // AFTER a change to SERIES, re-validate 

    useEffect(() => {
        handleBlur(null);
    }, [ 
        formData.outstanding_resource_requests,
        formData.mobs,  // formData.mobs_grouped, 
        formData.national_to_mobs, 
        formData.national_from_mobs, 
        formData.demobs // formData.demobs_grouped, 
    ]);

    // custom form BRING FORWARD handlers

    const bringForwardOutstandingResourceRequests = () => {
        if (
            !formData.hasOwnProperty('previous')
            || !formData.previous.hasOwnProperty('outstanding_resource_requests')
            || !formData.previous.outstanding_resource_requests
            || !formData.previous.outstanding_resource_requests.length
        ) {
            window.alert( t('There is no data to bring forward.') );
        } else {
            const isConfirmed = window.confirm( t('Are you sure you want to bring forward data? This will replace any associated data you may already have filled in.') );

            if ( isConfirmed ) {
                setFormData({
                    ...formData,
                    outstanding_resource_requests: formData.previous.outstanding_resource_requests
                });

                handleBlur(null);
            }
        }
    }

    const bringForwardNationalMobilizations = () => {
        if (
            !formData.hasOwnProperty('previous')

            || !formData.previous.hasOwnProperty('national_to_mobs')
            || !formData.previous.hasOwnProperty('national_from_mobs')

            || !formData.previous.national_to_mobs
            || !formData.previous.national_from_mobs

            // no guarantee the number and types of keys LINE UP, but so long as ONE has some items, we'll handle it
            || (
                !Object.keys(formData.previous.national_to_mobs).length
                && !Object.keys(formData.previous.national_from_mobs).length
            )
        ) {
            window.alert( t('There is no data to bring forward.') );
        }

        else {
            const isConfirmed = window.confirm( t('Are you sure you want to bring forward data? This will replace any associated data you may already have filled in.') );
            
            if ( isConfirmed ) {

                // combine all KEYS from both array into a single group
                let national_mobs_keys = [ ...Object.keys(formData.previous.national_to_mobs), ...Object.keys(formData.previous.national_from_mobs)];
                national_mobs_keys = [...new Set(national_mobs_keys)];

                // make a new ARRAY of just UNIQUE values
                console.log( 'national_mobs_keys', national_mobs_keys ); 

                // loop through all KEYS and make sure they exist in BOTH to and from objects
                national_mobs_keys.forEach((national_mobs_key) => {
                    if ( !formData.previous.national_to_mobs.hasOwnProperty(national_mobs_key) ) {
                        formData.previous.national_to_mobs[national_mobs_key] = initalDataNationalMobs;
                        formData.previous.national_to_mobs[national_mobs_key].resources = national_mobs_key;
                    }

                    if ( !formData.previous.national_from_mobs.hasOwnProperty(national_mobs_key) ) {
                        formData.previous.national_from_mobs[national_mobs_key] = initalDataNationalMobs;
                        formData.previous.national_from_mobs[national_mobs_key].resources = national_mobs_key;
                    }
                });

                // set FORM DATA
                setFormData({
                    ...formData,
                    national_to_mobs: formData.previous.national_to_mobs,
                    national_from_mobs: formData.previous.national_from_mobs
                });

                // trigger BLUR for syncing
                handleBlur(null);
            }
        }
    }   

    // format SUBMIT buttons and associated HANDLERs

    const handleSubmit = async ( submitType ) => {
        if ( isLoggedIn ) {

            // submit the CURRENT form data for validation, and only IF PASSES will we allow submit byt PASSING IN the type (this should be the ONLY WAY to change triggerSubmit state to anything but FALSE)
            handleBlur(null, true, submitType);

        }
    }  

    const [triggerSubmit, setTriggerSubmit] = useState(false);
    
    useEffect(() => {
        if ( triggerSubmit ) {
            
            console.log('SUBMIT triggered!');

            if (
                !Object.keys(formStatus.validationErrors).length
                && !formStatus.isLocked
            ) {

                const isConfirmed = window.confirm( t('Are you sure you want to publish this data?') );

                if ( isConfirmed ) {

                    // update FORM STATUS to display syncing is STARTING...
                    setFormStatus({
                        ...formStatus, 
                        isSyncing: true,
                        displayStatus: false,
                    });

                    let newFormStatus = {};

                    // figure out TYPE of form submit, use correct URL

                    let submitUrl = ApiEndpoints.dipSitrepNationalPublish();

                    axios
                        .post(
                            submitUrl,
                            formData,
                            {
                                headers: {
                                    Authorization: "Bearer " + activeUser.jwt
                                }
                            }
                        )
                        .then(({ data }) => {
                            
                            console.log('SUBMITTED successfully', data);

                            // figure out default status, and then determine DISPLAYABLE status from constant file
                            let displayStatus = 'N';
                            if ( data.status ) displayStatus = data.status.toUpperCase(); // currently, NATIONAL sitrep doesn't have a "state", but we'll keep an eye for future addition?
                            if ( data.last_edit && !data.status ) displayStatus = 'UNPUBLISHED'; // unpublished "state", if we don't recognize a passed-in "state" from API
                            if ( data.is_published ) displayStatus = 'PUBLISHED'; // published "state"
                            displayStatus = FormStatuses[displayStatus].title;
    
                            newFormStatus = {
                                isSyncing: false,
                                isError: false,
                                displayLastUpdated: data.last_edit,
                                displayStatus: displayStatus,
                                validationErrors: data.validationErrors || {}
                            }

                            if ( data.hasOwnProperty('requires_manual_sync') ) {
                                if (
                                    data.requires_manual_sync === true
                                    || data.requires_manual_sync.toString().toUpperCase() === 'T'
                                    || data.requires_manual_sync.toString().toUpperCase() === 'TRUE'
                                    || data.requires_manual_sync.toString().toUpperCase() === '1'
                                ) {
                                    newFormStatus.requiresManualSync = true;
                                } else {
                                    newFormStatus.requiresManualSync = false;
                                }
                            }
    
                            // update FORM STATUS with new form STATUS on success
                            setFormStatus({
                                ...formStatus,
                                ...newFormStatus
                            });

                            // ACKNOWLEDGE sync to server and REDIRECT away from form
                            window.alert( t('Data synced with server.')+' '+t('The action has been successfully saved.') );
                            history.push("/agency");

                        })
                        .catch((error) => {
                            console.log("SUBMITTED, with error", error);

                            newFormStatus = {
                                ...formStatus,
                                isSyncing: false,
                                isError: true,
                                validationErrors: error.response.data.validationErrors
                            }

                            if ( error.response.data.hasOwnProperty('last_edit') ) { newFormStatus.displayLastUpdated = error.response.data.last_edit }
                            
                            if ( error.response.data.hasOwnProperty('status') ) { 
                                // figure out default status, and then determine DISPLAYABLE status from constant file
                                newFormStatus.displayStatus = 'N';
                                if ( error.response.data.status ) newFormStatus.displayStatus = error.response.data.status.toUpperCase(); // currently, NATIONAL sitrep doesn't have a "state", but we'll keep an eye for future addition?
                                if ( error.response.data.last_edit && !error.response.data.status ) newFormStatus.displayStatus = 'UNPUBLISHED'; // unpublished "state", if we don't recognize a passed-in "state" from API
                                if ( error.response.data.is_published ) newFormStatus.displayStatus = 'PUBLISHED'; // published "state"
                                newFormStatus.displayStatus = FormStatuses[newFormStatus.displayStatus].title;
                            }

                            if ( error.response.data.hasOwnProperty('requires_manual_sync') ) {
                                if (
                                    error.response.data.requires_manual_sync === true
                                    || error.response.data.requires_manual_sync.toString().toUpperCase() === 'T'
                                    || error.response.data.requires_manual_sync.toString().toUpperCase() === 'TRUE'
                                    || error.response.data.requires_manual_sync.toString().toUpperCase() === '1'
                                ) {
                                    newFormStatus.requiresManualSync = true;
                                } else {
                                    newFormStatus.requiresManualSync = false;
                                }
                            }
                            
                            // update FORM STATUS to display and ERRORS from API (blindly replacing all that were there before?)
                            setFormStatus(newFormStatus);

                            // TOAST
                            toast.dismiss();
                            toast.error(
                                t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                            );

                        });

                } else {
                    console.log('SUBMITTED cancelled by confirm()');
                }

            }

            // ALWAYS set this back to FALSE afterwards
            setTriggerSubmit(false);

        }
    }, [ triggerSubmit ]);

    // render SUBMIT buttons, based on form status and user logged in

    let submitButtons = '';

    if (
        formStatus.isSyncing
    ) {
        
        submitButtons = <>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                        <FontAwesomeIcon icon={ solid('rotate') } />
                        { t('Syncing') }
                    </button>
                </div>
            </>

    } else if (
        formStatus.isLocked
    ) {
        
        submitButtons = <>
                <div className="input" data-validation={ t('This data is locked and currently cannot be edited.') }></div>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                        <FontAwesomeIcon icon={ solid('lock') } />
                        { t('Blocked') }
                    </button>
                </div>
            </>

    } else if (
        Object.keys(formStatus.validationErrors).length
    ) {
        
        submitButtons = 
            <>
                <div className="input" data-validation={ t('This data has one or more validation errors; please review it.') }></div>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                        <FontAwesomeIcon icon={ solid('lock') } />
                        { t('Blocked') }
                    </button>
                </div>
            </>

    } else {

        let submitButtonDisabled = false;
        // if ( formStatus.displayStatus === FormStatuses.PUBLISHED.title ) { submitButtonDisabled = true; }

        let publishLabel = t('Publish');
        let publishIcon = solid('file-signature');
        let publishDescription = '';

        if ( formStatus.displayStatus === FormStatuses.PUBLISHED.title ) {
            publishLabel = t('Republish');
            publishIcon = solid('file-import');
        }

        if ( formStatus.requiresManualSync ) {
            publishDescription = <p><i>{ t('The page has been updated. Changes will not be permanent until you save.') }</i></p>;
        }

        submitButtons = <>
            { publishDescription }

            <div className="form-submit-buttons">

                    <button type="button" disabled={submitButtonDisabled} className={ "button-submit " + FormStatuses.PUBLISHED.className } onClick={ () => handleSubmit('publish') }>
                        <FontAwesomeIcon icon={ publishIcon } />
                        { publishLabel }
                    </button>
                </div>
            </>

    }

    // STATIC data to display, based on AGENCY sitreps

    const [sitrepAgencySummaries, setSitrepAgencySummaries] = useState(<table></table>);
    
    const [sitrepAgenciesYesterday, setSitrepAgenciesYesterday] = useState(<table></table>);
    const [sitrepAgenciesFull, setSitrepAgenciesFull] = useState(<table></table>);
    const [sitrepAgenciesModified, setSitrepAgenciesModified] = useState(<table></table>);
    const [sitrepAgenciesMonitored, setSitrepAgenciesMonitored] = useState(<table></table>);
    const [sitrepAgenciesGrand, setSitrepAgenciesGrand] = useState(<table></table>);
    const [sitrepAgenciesPrescribed, setSitrepAgenciesPrescribed] = useState(<table></table>);
    const [sitrepAgenciesPriority, setSitrepAgenciesPriority] = useState(<table></table>);
    const [sitrepAgenciesPredictions, setSitrepAgenciesPredictions] = useState(<table></table>);
    const [sitrepAgenciesPreparednessLevels, setSitrepAgenciesPreparednessLevels] = useState(<table></table>);
    
    const [sitrepAgenciesResourceRequirements, setSitrepAgenciesResourceRequirements] = useState(<table></table>);
    const [sitrepAgenciesHelicopters, setSitrepAgenciesHelicopters] = useState(<table></table>);
    const [sitrepMobilizationsCompact, setSitrepMobilizationsCompact] = useState(<table></table>);
    /*
        // SECTION I agencies' demob compact REMOVED, as requested by client
        const [sitrepDemobilizationsCompact, setSitrepDemobilizationsCompact] = useState(<table></table>);
    */
    
    const [sitrepNationalAvailability, setSitrepNationalAvailability] = useState(<table></table>);

    useEffect(() => {

        if ( formData.sitrep ) {

            setSitrepAgencySummaries(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th>English</th>
                            <th>Français</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(formData.sitrep.agencies_sitereps).map(function(dataKey, index) {
                                return <tr key={index}>
                                    <td>{ dataKey }</td>
                                    <td className="nl2br">{ formData.sitrep.agencies_sitereps[dataKey].sitrep.field_en_operations_summary }</td>
                                    <td className="nl2br">{ formData.sitrep.agencies_sitereps[dataKey].sitrep.field_fr_operations_summary }</td>
                                </tr>
                            })
                        }
                    </tbody>
                </TableWrapper>
            );

            setSitrepAgenciesYesterday( <TableFireStatisticsAgenciesYesterday sitrep={formData.sitrep} agencyFullName={true} agencyLink={true} /> );
            setSitrepAgenciesFull( <TableFireStatisticsAgenciesFull sitrep={formData.sitrep} /> );
            setSitrepAgenciesModified( <TableFireStatisticsAgenciesModified sitrep={formData.sitrep} /> );
            setSitrepAgenciesMonitored( <TableFireStatisticsAgenciesMonitored sitrep={formData.sitrep} /> );
            setSitrepAgenciesGrand( <TableFireStatisticsAgenciesGrand sitrep={formData.sitrep} /> );
            setSitrepAgenciesPrescribed( <TableFireStatisticsAgenciesPrescribed sitrep={formData.sitrep} agencyFullName={true} /> );
            setSitrepAgenciesPriority( <TableFireStatisticsAgenciesPriority sitrep={formData.sitrep} expandedSitrepData={true} /> );
            setSitrepAgenciesPreparednessLevels( <TableFireStatisticsAgenciesPreparednessLevels sitrep={formData.sitrep} expandedSitrepData={true} /> );
            setSitrepAgenciesPredictions( <TableFireStatisticsAgenciesPredictions sitrep={formData.sitrep} agencyFullName={true} /> );
            
            setSitrepAgenciesResourceRequirements( <TableFireStatisticsAgenciesResourceRequirements sitrep={formData.sitrep} agencyFullName={true} expandedSitrepData={true} /> );
            setSitrepAgenciesHelicopters( <TableFireStatisticsAgenciesHelicopters sitrep={formData.sitrep} agencyFullName={true} /> );
            setSitrepMobilizationsCompact( <TableFireStatisticsMobilizationsCompact sitrep={formData.sitrep} /> );
            /*
                // SECTION I agencies' demob compact REMOVED, as requested by client
                setSitrepDemobilizationsCompact( <TableFireStatisticsDemobilizationsCompact sitrep={formData.sitrep} /> );
            */
            
            if ( formData.sitrep.agencies && Array.isArray(formData.sitrep.agencies) ) {
                if ( formData.sitrep.national_availability && Array.isArray(formData.sitrep.national_availability) ) {
                    setSitrepNationalAvailability( <TableMobilizationsNational agencies={ formData.sitrep.agencies } resourceOptions={ resourceOptions } resources={ formData.sitrep.resources } data={ formData.sitrep.national_availability } /> );
                }
            }

        }

    }, [ formData, i18n.language ]);

    // format display FORM TITLE

    let titleFormatted = t('CIFFC Sitrep for [field_date]');
    titleFormatted = titleFormatted.replace( '[field_date]', moment().format('ll') );

    // format display of sitrep STATUS

    let statusFormatted = '-';
    if (
        formStatus.isSyncing
    ) {
        statusFormatted = <em className={ FormStatuses.SYNCING.className }>{ t('Syncing') }</em>
    } else if (
        formStatus.isError
    ) {
         statusFormatted = <em className={ FormStatuses.ERROR.className }>{ FormStatuses.ERROR.title }</em>
    } else if (
        formStatus.displayStatus
    ) {
        statusFormatted = (formStatus.displayStatus===FormStatuses.E.title ? <em className={ FormStatuses.E.className }>{ formStatus.displayStatus }</em> : formStatus.displayStatus);
    }

    return(
      
        <section className="contentpage">
            <div className="container">

                { /* t(CIFFC SitRep Input') */ }
                <StaticContent staticContentAlias="sitrep-input-national" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                <form className={ "form-sitrep" + ( formStatus.isLocked ? ' is-locked' : '' ) }> 
                    <dl className="form-status">
                        <dt>{ t('Language') }</dt>
                        <dd>{ activeUser.user !== undefined ? i18n.language : null }</dd>

                        <dt>{ t('Today') }</dt>
                        <dd>{ moment().format('ll') }</dd>

                        <dt>{ t('Username') }</dt>
                        <dd>{ activeUser.user !== undefined ? activeUser.user.username : null }</dd>
                    </dl> 

                    <h3>{ titleFormatted }</h3>

                        <dl className="form-status ">
                            <dt>{ t('Status') }</dt>
                            <dd>{ statusFormatted }</dd>

                            <dt>{ t('Last update') }</dt>
                            <dd>
                                { !formStatus.isSyncing 
                                    ? (
                                        formStatus.displayLastUpdated 
                                            ? moment( formStatus.displayLastUpdated ).tz( moment.tz.guess() ).format('LLLL z') // purposely including TIME with displayed sync-ed data
                                            : '-'
                                        )
                                    : <em className="form-state-sync">{ t('Syncing') }</em>
                                }
                            </dd>
                        </dl>
                    
                    <fieldset className="form-wrapper" disabled={ formStatus.isLocked ? 'disabled' : false }>

                        <div className="input" data-validation={ t(formStatus.validationErrors.form_0) }></div>

                        { /* A */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_1) }></div>
                        
                            <fieldset className="form-section">
                                <legend>{ t('National Summary') }</legend>

                                <div className="input" data-validation={ t(formStatus.validationErrors.en_summary_comments) }>
                                    <em className="label-info">English</em>
                                    <textarea name="en_summary_comments" onChange={handleChange} onBlur={handleBlur} value={formData["en_summary_comments"]} />
                                </div>
                                <div className="input" data-validation={ t(formStatus.validationErrors.fr_summary_comments) }>   
                                    <em className="label-info">Français</em>
                                    <textarea name="fr_summary_comments" onChange={handleChange} onBlur={handleBlur} value={formData["fr_summary_comments"]} />        
                                </div>

                                <fieldset className="input radios" data-validation={ t(formStatus.validationErrors.preparedness_level) }>
                                    <legend>{ t('National Preparedness Level') }</legend>
                                    
                                    <div className="input">
                                        <input type="radio" name="preparedness_level" onChange={handleChange} checked={formData.preparedness_level.toString()==='1'} value="1" />
                                        <label>1</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="preparedness_level" onChange={handleChange} checked={formData.preparedness_level.toString()==='2'} value="2" />
                                        <label>2</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="preparedness_level" onChange={handleChange} checked={formData.preparedness_level.toString()==='3'} value="3" />
                                        <label>3</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="preparedness_level" onChange={handleChange} checked={formData.preparedness_level.toString()==='4'} value="4" />
                                        <label>4</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="preparedness_level" onChange={handleChange} checked={formData.preparedness_level.toString()==='5'} value="5" />
                                        <label>5</label>
                                    </div>
                                </fieldset>
                                
                                <div className="input" data-validation={ t(formStatus.validationErrors.duty_officer) }>
                                    <label>{ t('Current Duty Officer') }</label>
                                    <select name="duty_officer" onChange={handleChange} onBlur={handleBlur} value={formData.duty_officer}>
                                        <option></option>
                                        {
                                            dutyOfficersData.map( (item, index) => {
                                                return <option key={index} value={item.id}>{ item.name }</option>
                                            })
                                        }
                                    </select>
                                </div>

                                <fieldset className="input">
                                    <legend>{ t('Agency Situation and Preparedness Summary') }</legend>
                                    
                                    { sitrepAgencySummaries }
                                </fieldset>
                                
                            </fieldset>

                        { /* B */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_2) }></div>
                        
                            <fieldset className="form-section">
                                <legend>{ t('Fire Statistics') }</legend>

                                <label>{ t('New Wildland Fires Yesterday') }</label>

                                { sitrepAgenciesYesterday }

                                <label>{ t('Full Response') }</label>

                                { sitrepAgenciesFull }

                                <div className="table-sidebyside">
                                <div>
                                    <label>{ t('Modified Response') }</label>
                                    { sitrepAgenciesModified }
                                </div>
                                <div>
                                    <label>{ t('Monitored Response') }</label>
                                    { sitrepAgenciesMonitored }
                                </div>
                                <div>
                                    <label>{ t('Grand Totals') }</label>
                                    { sitrepAgenciesGrand }
                                </div>
                                </div>

                                <label>{ t('Prescribed Fires') }</label>

                                { sitrepAgenciesPrescribed }
                                
                            </fieldset>

                        { /* C */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_3) }></div>

                            <fieldset className="form-section">
                                <legend>{ t('Priority Fires') }</legend>

                                { sitrepAgenciesPriority }
                            </fieldset>

                        { /* D */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_4) }></div>
                        
                            <fieldset className="form-section">
                                <legend>{ t('Fire Occurrence Prediction for Tomorrow') }</legend>

                                { sitrepAgenciesPredictions }
                            </fieldset>

                        { /* E */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_5) }></div>
                            
                            <fieldset className="form-section">
                                <legend>{ t('Preparedness Levels') }</legend>

                                { sitrepAgenciesPreparednessLevels }
                            </fieldset>

                        { /* F */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_6) }></div>
                            
                            <fieldset className="form-section">
                                <legend>
                                    { t('Possibility of Resource Requirements') }
                                </legend>

                                <fieldset>
                                    <legend>{ t('Agency Resource Requirements') }</legend>
                                    
                                    { sitrepAgenciesResourceRequirements }
                                </fieldset>

                                <fieldset className="sub-series">
                                    <legend>
                                        { t('Outstanding Resource Requests') }

                                        <button type="button" className="link button-link" onClick={ bringForwardOutstandingResourceRequests }>
                                            <FontAwesomeIcon icon={ solid('file-import') } />
                                            { t("Bring Forward Yesterday’s Data") }
                                        </button>
                                    </legend>

                                    <div className="input-series">
                                        {
                                            formData.outstanding_resource_requests.map( (item, index) => (
                                                <div className="input-series-set" key={item.seriesKey}>

                                                    <fieldset className="input">
                                                        <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('outstanding_resource_requests') && formStatus.validationErrors.outstanding_resource_requests.hasOwnProperty(index) ? t(formStatus.validationErrors.outstanding_resource_requests[index].for_agency) : null }>
                                                            <label>{ t('For') }</label>
                                                            <input type="text" name={"outstanding_resource_requests["+index+"].for_agency"} onChange={handleChange} onBlur={handleBlur} value={ formData.outstanding_resource_requests[index].for_agency } />
                                                        </div>

                                                        <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('outstanding_resource_requests') && formStatus.validationErrors.outstanding_resource_requests.hasOwnProperty(index) ? t(formStatus.validationErrors.outstanding_resource_requests[index].resources_en) : null }>
                                                            <label>{ t('Resources') }, <em className="label-info">English</em></label>
                                                            <input type="text" name={"outstanding_resource_requests["+index+"].resources_en"} onChange={handleChange} onBlur={handleBlur} value={ formData.outstanding_resource_requests[index].resources_en } />
                                                        </div>

                                                        <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('outstanding_resource_requests') && formStatus.validationErrors.outstanding_resource_requests.hasOwnProperty(index) ? t(formStatus.validationErrors.outstanding_resource_requests[index].resource_fr) : null }>
                                                            <label>{ t('Resources') }, <em className="label-info">Français</em></label>
                                                            <input type="text" name={"outstanding_resource_requests["+index+"].resources_fr"} onChange={handleChange} onBlur={handleBlur} value={ formData.outstanding_resource_requests[index].resources_fr } />
                                                        </div>
                                                    </fieldset>

                                                    <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow("outstanding_resource_requests", item.seriesKey) }>
                                                        <FontAwesomeIcon icon={ solid('delete-left') } />
                                                        <span className="button-label">{ t('Remove') }</span>
                                                    </button>

                                                </div>
                                            ))
                                        }
                                    </div>

                                    <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("outstanding_resource_requests", initialDataOutstandingResourceRequests) }>
                                        <FontAwesomeIcon icon={ solid('list') } />
                                        { t('Add another item') }
                                    </button>
                                </fieldset>
                                
                            </fieldset>

                        { /* G */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_7) }></div>
                            
                            <fieldset className="form-section">
                                <legend>{ t('Casual Hire Helicopters') }</legend>

                                { sitrepAgenciesHelicopters }
                            </fieldset>

                        { /* H */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_8) }></div>
                            
                            <fieldset className="form-section">
                                <legend>{ t('Mobilizations') }</legend>

                                <fieldset className="sub-series">
                                    <legend>{ t('Today\'s Mobilizations') }</legend>

                                    <div className="input-series">
                                        {
                                            formData.hasOwnProperty('mobs_grouped')
                                                ? formData.mobs_grouped.map( (groupItem, groupIndex) => {

                                                    const thisObjectRef = "mobs_grouped["+groupIndex+"]";
                                                    
                                                    let thisInitialDataMobs = { ...initialDataMobs };

                                                    // if there is a FIRST in this subgroup, populate the next added item in group with some of same info...
                                                    const thisObjectRefFirstInArray = thisObjectRef+"[0]";
                                                    const firstInArray = _.get(formData, thisObjectRefFirstInArray);
                                                    if ( firstInArray!==undefined ) { thisInitialDataMobs = { ...initialDataMobs, to: firstInArray.to, from: firstInArray.from }; }
                                                    
                                                    const handleSeriesAddRows = () => seriesAddRow( thisObjectRef, thisInitialDataMobs );

                                                    return <div className="input-series-set" key={groupIndex}>
                                                        
                                                        <div className="input-series without-duplicate-labels without-dividing-lines">
                                                            {
                                                                groupItem.map( (item, index) => {

                                                                    // look through VALIDATIONs for original array and seet if we can find a MATCH...

                                                                    let thisGroupItemValidation = initialDataMobs;
                                                                    if ( formStatus.validationErrors.hasOwnProperty('mobs') ) {
                                                                        for (const validationKey in formStatus.validationErrors.mobs) {
                                                                            if ( formData.mobs[validationKey]===item ) {
                                                                                thisGroupItemValidation = { ...thisGroupItemValidation, ...formStatus.validationErrors.mobs[validationKey] };
                                                                            }
                                                                        }
                                                                    }

                                                                    if (
                                                                        formStatus.validationErrors.hasOwnProperty('mobs_grouped')
                                                                        && formStatus.validationErrors.mobs_grouped.hasOwnProperty(groupIndex)
                                                                        && formStatus.validationErrors.mobs_grouped[groupIndex].hasOwnProperty(index)
                                                                    ) {
                                                                        thisGroupItemValidation = { ...thisGroupItemValidation, ...formStatus.validationErrors.mobs_grouped[groupIndex][index] };
                                                                    }

                                                                    // and make SOME FIELDs restricted.matched to the values of the INITIAL in group...

                                                                    let isDisabledAttr = {};
                                                                    
                                                                    if ( index>0 ) {
                                                                        formData.mobs_grouped[groupIndex][index].to = formData.mobs_grouped[groupIndex][0].to;
                                                                        formData.mobs_grouped[groupIndex][index].from = formData.mobs_grouped[groupIndex][0].from;

                                                                        isDisabledAttr.disabled = "disabled";
                                                                    }

                                                                    

                                                                    // render this series within this GROUP, with it own add BUTTON

                                                                    return <div className="input-series-set" key={item.seriesKey+'-'+index}>
                                                                        
                                                                            <fieldset className="input">
                                                                                <div className="input" data-validation={ thisGroupItemValidation.to || null }>
                                                                                    <label>{ t('To') }</label>
                                                                                    <input type="text" name={"mobs_grouped["+groupIndex+"]["+index+"].to"} onChange={handleChange} onBlur={handleBlur} value={ formData.mobs_grouped[groupIndex][index].to } {...isDisabledAttr} />
                                                                                </div>

                                                                                <div className="input wide with-input-series-add" data-validation={ thisGroupItemValidation.resources || null }>
                                                                                    <ResourceOptionSet inputName={"mobs_grouped["+groupIndex+"]["+index+"].resources"} inputValue={ formData.mobs_grouped[groupIndex][index].resources } onBlur={handleBlur} resourceData={resourceOptionsExpanded} formData={formData} setFormData={setFormData} useAmounts={true} useLabels={true} />

                                                                                    {
                                                                                        index===groupItem.length-1
                                                                                            ? <button className="link input-series-add" type="button" onClick={ handleSeriesAddRows }>
                                                                                                <FontAwesomeIcon icon={ solid('list') } />
                                                                                                { t('Add another item') }
                                                                                            </button>
                                                                                            : null
                                                                                    }
                                                                                </div>

                                                                                <div className="input" data-validation={ thisGroupItemValidation.from || null }>
                                                                                    <label>{ t('From') }</label>
                                                                                    <input type="text" name={"mobs_grouped["+groupIndex+"]["+index+"].from"} onChange={handleChange} onBlur={handleBlur} value={ formData.mobs_grouped[groupIndex][index].from } {...isDisabledAttr} />
                                                                                </div>
                                                                            </fieldset>

                                                                            <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow( thisObjectRef, item.seriesKey) }>
                                                                                <FontAwesomeIcon icon={ solid('delete-left') } />
                                                                                <span className="button-label">{ t('Remove') }</span>
                                                                            </button>

                                                                        </div>
                                                                })
                                                            }
                                                        </div>

                                                    </div>
                                                })
                                                : null
                                        }
                                    </div>

                                    <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("mobs_grouped", [initialDataMobs]) }>
                                        <FontAwesomeIcon icon={ solid('list') } />
                                        { t('Add another item') }
                                    </button>

                                    {
                                    /*  
                                    <div className="input-series">
                                        {
                                            formData.mobs.map( (item, index) => (
                                                <div className="input-series-set" key={item.seriesKey}>
                                                    
                                                    <fieldset className="input">
                                                        <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('mobs') && formStatus.validationErrors.mobs.hasOwnProperty(index) ? t(formStatus.validationErrors.mobs[index].to) : null }>
                                                            <label>{ t('To') }</label>
                                                            <input type="text" name={"mobs["+index+"].to"} onChange={handleChange} onBlur={handleBlur} value={ formData.mobs[index].to } />
                                                        </div>

                                                        <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('mobs') && formStatus.validationErrors.mobs.hasOwnProperty(index) ? t(formStatus.validationErrors.mobs[index].resources) : null }>
                                                            <ResourceOptionSet inputName={"mobs["+index+"].resources"} inputValue={ formData.mobs[index].resources } onBlur={handleBlur} resourceData={resourceOptionsExpanded} formData={formData} setFormData={setFormData} useAmounts={true} useLabels={true} />
                                                        </div>

                                                        <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('mobs') && formStatus.validationErrors.mobs.hasOwnProperty(index) ? t(formStatus.validationErrors.mobs[index].from) : null }>
                                                            <label>{ t('From') }</label>
                                                            <input type="text" name={"mobs["+index+"].from"} onChange={handleChange} onBlur={handleBlur} value={ formData.mobs[index].from } />
                                                        </div>
                                                    </fieldset>

                                                    <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow("mobs", item.seriesKey) }>
                                                        <FontAwesomeIcon icon={ solid('delete-left') } />
                                                        <span className="button-label">{ t('Remove') }</span>
                                                    </button>

                                                </div>
                                            ))
                                        }
                                    </div>

                                    <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("mobs", initialDataMobs) }>
                                        <FontAwesomeIcon icon={ solid('list') } />
                                        { t('Add another item') }
                                    </button>
                                    */
                                    }

                                </fieldset>

                                <fieldset className="sub-series">
                                    <legend>
                                        { t('National Mobilizations') }

                                        <button type="button" className="link button-link" onClick={ bringForwardNationalMobilizations }>
                                            <FontAwesomeIcon icon={ solid('file-import') } />
                                            { t("Bring Forward Yesterday’s Data") }
                                        </button>
                                    </legend>

                                    <fieldset className="sub-series">
                                        <legend>{ t('National Mobilizations: To') }</legend>

                                            {
	                                            !resourceData ? null : resourceData.categories.map((c,i) => {
		                                            return <React.Fragment key={"to-" + c + "-" + i}>
													<div className="input-series without-add-button without-duplicate-labels">
		                                            {
			                                             resourceData.subcategories[c].map((s,j) => {
				                                            return resourceData.resources[c][s].map((itemName,k) => {
					                                            let resourceShortname = resourceData.shortnames[c][s][itemName];
					                                            
					                                            if( formData.national_to_mobs.hasOwnProperty(resourceShortname) ){
						                                            let nationalIndex = "to-" + c + "-" + s + "-" + resourceShortname;
						                                            return <div key={nationalIndex} className="input-series-set">
				                                                        <fieldset className="input">
				                                                            <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('national_to_mobs') && formStatus.validationErrors.national_to_mobs.hasOwnProperty(resourceShortname) ? t(formStatus.validationErrors.national_to_mobs[resourceShortname].resources) : null }>
				                                                                <label>{ t(c) }</label>
				                                                                <input type="text" name={"national_to_mobs["+resourceShortname+"].resources"} disabled="disabled" value={ formData.national_to_mobs[resourceShortname].resources } />
				                                                            </div>
				

				                                                            {
				                                                                Object.keys(AgenciesData).map( (agencyCode, agencyIndex) => (
				                                                                    <div key={nationalIndex+"-"+agencyIndex} className="input thin" data-validation={ formStatus.validationErrors.hasOwnProperty('national_to_mobs') && formStatus.validationErrors.national_to_mobs.hasOwnProperty(resourceShortname) ? t(formStatus.validationErrors.national_to_mobs[resourceShortname][agencyCode]) : null }>
				                                                                        <label>{ agencyCode.toUpperCase() }</label>
				                                                                        <input type="number" min="0" name={"national_to_mobs["+resourceShortname+"]["+agencyCode+"]"} onChange={handleChange} onBlur={handleBlur} value={ formData.national_to_mobs[resourceShortname][agencyCode] || "" } />
				                                                                    </div>
				                                                                )) 
				                                                            }

                                                                            <div className="input thin" data-validation={ formStatus.validationErrors.hasOwnProperty('national_to_mobs') && formStatus.validationErrors.national_to_mobs.hasOwnProperty(resourceShortname) ? t(formStatus.validationErrors.national_to_mobs[resourceShortname].ciffc) : null }>
                                                                                <label>CIFFC</label>
                                                                                <input type="number" min="0" name={"national_to_mobs["+resourceShortname+"][ciffc]"} onChange={handleChange} onBlur={handleBlur} value={ formData.national_to_mobs[resourceShortname].ciffc || "" } />
                                                                            </div>

                                                                            <div className="input thin" data-validation={ formStatus.validationErrors.hasOwnProperty('national_to_mobs') && formStatus.validationErrors.national_to_mobs.hasOwnProperty(resourceShortname) ? t(formStatus.validationErrors.national_to_mobs[resourceShortname].intl) : null }>
                                                                                <label>Intl</label>
                                                                                <input type="number" min="0" name={"national_to_mobs["+resourceShortname+"][intl]"} onChange={handleChange} onBlur={handleBlur} value={ formData.national_to_mobs[resourceShortname].intl || "" } />
                                                                            </div>

				
				                                                            <div key={nationalIndex+"-total"} className="input thin">
				                                                                <label>{ t('Total') }</label>
				                                                                <input type="text" disabled="disabled" readOnly="readonly" name={"national_to_mobs["+resourceShortname+"][total]"} value={
				                                                                        Object.keys({...AgenciesData,...{'ciffc':'','intl':''}}).reduce( (next, agencyCode) => {
				                                                                            return parseInt(next) + ( parseInt(formData.national_to_mobs[resourceShortname][agencyCode]) || 0 );
				                                                                        }, 0) 
				                                                                    } />
				                                                            </div>
				                                                        </fieldset>
				
				                                                        <button type="button" className="input-series-remove" onClick={ () => { seriesRemoveRow("national_to_mobs", resourceShortname); } }>
				                                                            <FontAwesomeIcon icon={ solid('delete-left') } />
				                                                            <span className="button-label">{ t('Remove') }</span>
				                                                        </button>
				
				                                                    </div>
;
					                                            }else{
						                                            return '';
					                                            }
				                                            })
			                                            })
	                                            }
												</div>
	                                            </React.Fragment>;
	                                            })
                                            }
                                    </fieldset>

                                    <fieldset className="sub-series">
                                        <legend>{ t('National Mobilizations: From') }</legend>

                                            {
	                                            !resourceOptionsShort ? null : resourceOptionsShort.categories.map((c,i) => {
		                                            return <React.Fragment key={"from-" + c + "-" + i}>
													<div className="input-series without-add-button without-duplicate-labels">
		                                            {
			                                             resourceData.subcategories[c].map((s,j) => {
				                                            return resourceData.resources[c][s].map((itemName,k) => {
					                                            let resourceShortname = resourceData.shortnames[c][s][itemName];
					                                            if( formData.national_from_mobs.hasOwnProperty(resourceShortname) ){
						                                            let nationalIndex = "from-" + c + "-" + s + "-" + resourceShortname;
						                                            return <div key={nationalIndex} className="input-series-set">
                                                                            
                                                                            <fieldset className="input">
                                                                                <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('national_from_mobs') && formStatus.validationErrors.national_from_mobs.hasOwnProperty(resourceShortname) ? t(formStatus.validationErrors.national_from_mobs[resourceShortname].resources) : null }>
                                                                                    <label>{ t(c) }</label>
                                                                                    <input type="text" name={"national_from_mobs["+resourceShortname+"].resources"} disabled="disabled" value={ formData.national_from_mobs[resourceShortname].resources } />
                                                                                </div>



                                                                                {
                                                                                    Object.keys(AgenciesData).map( (agencyCode, agencyIndex) => (
                                                                                        <div key={nationalIndex+"-"+agencyIndex} className="input thin" data-validation={ formStatus.validationErrors.hasOwnProperty('national_from_mobs') && formStatus.validationErrors.national_from_mobs.hasOwnProperty(resourceShortname) ? t(formStatus.validationErrors.national_from_mobs[resourceShortname][agencyCode]) : null }>
                                                                                            <label>{ agencyCode.toUpperCase() }</label>
                                                                                            <input type="number" min="0" name={"national_from_mobs["+resourceShortname+"]["+agencyCode+"]"} onChange={handleChange} onBlur={handleBlur} value={ formData.national_from_mobs[resourceShortname][agencyCode] || "" } />
                                                                                        </div>
                                                                                    )) 
                                                                                }

                                                                                <div className="input thin" data-validation={ formStatus.validationErrors.hasOwnProperty('national_from_mobs') && formStatus.validationErrors.national_from_mobs.hasOwnProperty(resourceShortname) ? t(formStatus.validationErrors.national_from_mobs[resourceShortname].ciffc) : null }>
                                                                                    <label>CIFFC</label>
                                                                                    <input type="number" min="0" name={"national_from_mobs["+resourceShortname+"][ciffc]"} onChange={handleChange} onBlur={handleBlur} value={ formData.national_from_mobs[resourceShortname].ciffc || "" } />
                                                                                </div>
    
                                                                                <div className="input thin" data-validation={ formStatus.validationErrors.hasOwnProperty('national_from_mobs') && formStatus.validationErrors.national_from_mobs.hasOwnProperty(resourceShortname) ? t(formStatus.validationErrors.national_from_mobs[resourceShortname].intl) : null }>
                                                                                    <label>Intl</label>
                                                                                    <input type="number" min="0" name={"national_from_mobs["+resourceShortname+"][intl]"} onChange={handleChange} onBlur={handleBlur} value={ formData.national_from_mobs[resourceShortname].intl || "" } />
                                                                                </div>



                                                                                <div key={nationalIndex+"-total"} className="input thin">
                                                                                    <label>{ t('Total') }</label>
                                                                                    <input type="text" disabled="disabled" readOnly="readonly" name={"national_from_mobs["+resourceShortname+"][total]"} value={
                                                                                            Object.keys({...AgenciesData,...{'ciffc':'','intl':''}}).reduce( (next, agencyCode) => {
                                                                                                return parseInt(next) + ( parseInt(formData.national_from_mobs[resourceShortname][agencyCode]) || 0 );
                                                                                            }, 0) 
                                                                                        } />
                                                                                </div>
                                                                            </fieldset>

                                                                            <button type="button" className="input-series-remove" onClick={ () => { seriesRemoveRow("national_to_mobs", resourceShortname); } }>
                                                                                <FontAwesomeIcon icon={ solid('delete-left') } />
                                                                                <span className="button-label">{ t('Remove') }</span>
                                                                            </button>

                                                                        </div>;
					                                            }else{
						                                            return '';
					                                            }
				                                            })
			                                            })
	                                            }
												</div>
	                                            </React.Fragment>;
	                                            })
                                            }
                                    </fieldset>

                                    <fieldset className="input-series-add-with-fields">
                                        <FontAwesomeIcon icon={ solid('truck-ramp-box') } />
                                    
                                        <ResourceOptionSet inputName="new_resource_key" inputValue={ formNational.new_resource_key } onBlur={ ()=>null } resourceData={resourceOptionsShort} formData={formNational} setFormData={setFormNational} returnNameOnly={true} returnShortName={true} />

                                        <FontAwesomeIcon icon={ solid('chevron-right') } />

                                        <button className="link input-series-add" disabled={ formNational.new_resource_key ? false : 'disabled' } type="button" onClick={ () => seriesAddRow("national_to_mobs", { ...initalDataNationalMobs, resources: formNational.new_resource_key }, formNational.new_resource_key) }>
                                            <FontAwesomeIcon icon={ solid('list') } />
                                            { t('Add another item') }
                                        </button>
                                    </fieldset>

                                </fieldset>

                                <fieldset className="input">
                                    <legend>{ t('Compact Agreement Mobilizations') }</legend>
                                    
                                    { sitrepMobilizationsCompact }
                                </fieldset>
                            </fieldset>

                        { /* I */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_9) }></div>
                            
                            <fieldset className="form-section">
                                <legend>{ t('Demobilizations') }</legend>

                                <fieldset className="sub-series">
                                    <legend>{ t('Today\'s Demobilizations') }</legend>

                                    <div className="input-series">
                                        {
                                            formData.demobs.map( (item, index) => (
                                                <div className="input-series-set" key={item.seriesKey}>
                                                    
                                                    <fieldset className="input">
                                                        <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('demobs') && formStatus.validationErrors.demobs.hasOwnProperty(index) ? t(formStatus.validationErrors.demobs[index].to) : null }>
                                                            <label>{ t('To') }</label>
                                                            <input type="text" name={"demobs["+index+"].to"} onChange={handleChange} onBlur={handleBlur} value={ formData.demobs[index].to } />
                                                        </div>

                                                        <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('demobs') && formStatus.validationErrors.demobs.hasOwnProperty(index) ? t(formStatus.validationErrors.demobs[index].resources) : null }>
                                                            <ResourceOptionSet inputName={"demobs["+index+"].resources"} inputValue={ formData.demobs[index].resources } onBlur={handleBlur} resourceData={resourceOptionsExpanded} formData={formData} setFormData={setFormData} useAmounts={true} useLabels={true} />
                                                        </div>

                                                        <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('demobs') && formStatus.validationErrors.demobs.hasOwnProperty(index) ? t(formStatus.validationErrors.demobs[index].from) : null }>
                                                            <label>{ t('From') }</label>
                                                            <input type="text" name={"demobs["+index+"].from"} onChange={handleChange} onBlur={handleBlur} value={ formData.demobs[index].from } />
                                                        </div>
                                                    </fieldset>

                                                    <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow("demobs", item.seriesKey) }>
                                                        <FontAwesomeIcon icon={ solid('delete-left') } />
                                                        <span className="button-label">{ t('Remove') }</span>
                                                    </button>

                                                </div>
                                            ))
                                        }
                                    </div>

                                    <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("demobs", initialDataDemobs) }>
                                        <FontAwesomeIcon icon={ solid('list') } />
                                        { t('Add another item') }
                                    </button>

                                </fieldset>

                                {
                                    /*

                                    // SECTION I agencies' demob compact REMOVED, as requested by client

                                    <fieldset className="input">
                                        <legend>{ t('Compact Agreement Demobilizations') }</legend>
                                        
                                        { sitrepDemobilizationsCompact }
                                    </fieldset>
                                    
                                    */
                                }
                            </fieldset>

                        { /* J */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_10) }></div>
                            
                            <fieldset className="form-section">
                                <legend>{ t('Resource Availability Report') }</legend>

                                { sitrepNationalAvailability }
                            </fieldset>


                        { /* K */ }

                            <div className="input" data-validation={ t(formStatus.validationErrors.form_11) }></div>

                            <fieldset className="form-section">
                                <legend>{ t('US Fire Activity') }</legend>

                                <fieldset className="input radios" data-validation={ t(formStatus.validationErrors.us_preparedness_level) }>
                                    <legend>{ t('National Preparedness Level') }</legend>
                                    
                                    <div className="input">
                                        <input type="radio" name="us_preparedness_level" onChange={handleChange} checked={formData.us_preparedness_level.toString()==='1'} value="1" />
                                        <label>1</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="us_preparedness_level" onChange={handleChange} checked={formData.us_preparedness_level.toString()==='2'} value="2" />
                                        <label>2</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="us_preparedness_level" onChange={handleChange} checked={formData.us_preparedness_level.toString()==='3'} value="3" />
                                        <label>3</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="us_preparedness_level" onChange={handleChange} checked={formData.us_preparedness_level.toString()==='4'} value="4" />
                                        <label>4</label>
                                    </div>

                                    <div className="input">
                                        <input type="radio" name="us_preparedness_level" onChange={handleChange} checked={formData.us_preparedness_level.toString()==='5'} value="5" />
                                        <label>5</label>
                                    </div>
                                </fieldset>

                                <fieldset className="input">
                                    <div className="input wide" data-validation={ t(formStatus.validationErrors.us_fires) }>
                                        <label>{ t('Fires Yesterday') }</label>
                                        <input type="text" name="us_fires" onChange={handleChange} onBlur={handleBlur} value={formData.us_fires} />
                                    </div>

                                    <div className="input wide" data-validation={ t(formStatus.validationErrors.us_acres) }>
                                        <label>{ t('Acres Yesterday') }</label>
                                        <input type="text" name="us_acres" onChange={handleChange} onBlur={handleBlur} value={formData.us_acres} />
                                    </div>

                                    <div className="input wide" data-validation={ t(formStatus.validationErrors.us_ytd_fires) }>
                                        <label>{ t('YTD Fires') }</label>
                                        <input type="number" name="us_ytd_fires" onChange={handleChange} onBlur={handleBlur} value={formData.us_ytd_fires} min="0" />
                                    </div>

                                    <div className="input wide" data-validation={ t(formStatus.validationErrors.us_ytd_acres) }>
                                        <label>{ t('YTD Acres') }</label>
                                        <input type="number" name="us_ytd_acres" onChange={handleChange} onBlur={handleBlur} value={formData.us_ytd_acres} min="0" />
                                    </div>
                                </fieldset>
                            </fieldset>

                    </fieldset>
                    
                    { submitButtons }

                </form>

                <p className="back">
                    <Link to="/agency">
                        <FontAwesomeIcon icon={ solid('arrow-left') } />
                        { t('Overview') }
                    </Link>
                </p>

            </div>

            <Toaster
                  toastOptions={{
                    
                    // Default options
                    className: 'toast-popup',

                    // Default options for specific types
                    error: {
                        duration: 4000,
                        position: 'top-center',

                        className: 'toast-popup toast-error',

                        iconTheme: {
                            primary: 'red',
                            secondary: 'white',
                        },
                    },
                
                    // Default options for specific types
                    success: {
                        duration: 750,
                        position: 'top-center',

                        className: 'toast-popup toast-notify',

                        iconTheme: {
                            primary: 'white',
                            secondary: '#18b4cd',
                        },
                    },
                  }}
            />
        </section>

    );

}

export default NationalSitrepInput;